<template>
    <div v-if="loaded" :class="mainClass">

        <label v-if="!!!st('label_link')" :class="' input_' + st('name') + ' ' + st('label_class')">{{ st('label') }}</label>

        <div v-if="st('type')=='radio' && !!st('options')">
            <template v-for="option in st('options')" :key="'input_' + st('name') + '_key_' + option.label">
                <input
                    :id="'inputID_' + st('name') + '_option_' + option.value" 
                    class="input-radio"
                    :name="'inputID_' + st('name')" 
                    :type="st('type')"
                    :value="option.value"
                    :maxlength="gt_max_length()"
                    v-model="value"
                />
                <label :for="'inputID_' + st('name') + '_option_' + option.value" class="span-input-radio">{{option.label}}</label>
            </template>
        </div>

        <div v-else-if="st('type')=='radio_disabled' && !!st('options')">
            <template v-for="(option, key) in st('options')" :key="'input_' + st('name') + '_key_' + option.label">
                <input
                    :id="'inputID_' + st('name') + '_option_' + option.value" 
                    :class="'input-radio' + (key == 0 ? ' input-radio-checked' : '')"
                    :name="'inputID_' + st('name')" 
                    :type="st('type')"
                    :value="option.value"
                    :maxlength="gt_max_length()"
                    v-model="value"
                    disabled
                />
                <label :for="'inputID_' + st('name') + '_option_' + option.value" class="span-input-radio">{{option.label}}</label>
            </template>
        </div>

        <div class="col-md-12 position-relative lada-border" v-else-if="st('type')=='options'">
            <input @click="open_search" @blur="close_search" @keyup="search" 
                :class="'search-input-' + st('name') + ' ' + st('input_class')" v-if="st('search')" 
                :id="'inputID_' + st('name')" 
                :type="st('type')" 
                :name="st('name')" 
                :maxlength="gt_max_length()" 
                :placeholder="(st('name') == 'domain' || st('name') == 'familiar_domain' || st('name').includes('beneficiary_domain_')) ? placeholders.domain : '' "/>
            
            <select v-model="input_value" v-if="!st('search')" :id="'selectID_' + st('name')">
                <option value=""> Select </option>
                <option v-for="option in st('options')" :key="'input_' + st('name') + '_key_' + option.name" :value="option.value">
                    {{option.name}}
                </option>
            </select>
            <ul class="search-options" :id="'search-selectID_' + st('name')">
                <li @click="selected_option($event, option)" v-for="option in st('options')" :key="'li_' + st('name') + '_key_' + option.name">{{option.name}}</li>
            </ul>
        </div>

        <label v-else-if="st('type')=='checkbox'" class="d-flex label-checkbox" :for="'inputID_' + st('name')">
            <input :id="'inputID_' + st('name')" :type="st('type')" :class="st('input_class')" :name="'inputID_' + st('name')" v-model="value" :maxlength="gt_max_length()"/>
            <span :for="'inputID_' + st('name')"></span>
            <div class="ml-1">
                {{ st('label') }}
                <router-link :to="st('link_vue')">{{ st('label_link') }}</router-link>
            </div>
        </label>

        <input v-else-if="gt_type()== 'file'" :id="'inputID_' + st('name')" :name="st('name')" :type="gt_type()" :class="st('input_class')"  @change="onChangeFile($event)" accept="application/pdf"/>
        <div class="my-2 text-bold" v-else-if="st('readonly')">
            {{value}}
        </div>
        <input v-else :id="'inputID_' + st('name')" :name="st('name')" :type="gt_type()" :class="st('input_class')" v-model="value" :maxlength="gt_max_length()" @keyup="verify_regex"/>
        
    </div>
</template>
  
<script>
    
    import { mapMutations, mapActions } from "vuex";

    export default {
        data() {
            return {
                'input_value': "",
                'loaded': false,
                'default_max_length': 50,
                'placeholders': {
                    'domain': "@example.com",
                }
            }
        },
        props: {
            settings: {
                Default: Object,
                default: function () {
                    return {
                        class_styles: '',
                        required_length: '',
                        max_length: '',
                        label_link: '',
                        label: '',
                        link_vue: '',
                        mutation: '',
                        name: '',
                        options: [],
                        no_spacing: false,
                        required: false,
                        search: false,
                        type: 'text',
                        value: '',
                        label_class: '',
                        input_class: '',
                        search_type: '',
                        array_key: -1,
                        'readonly': false,
                        
                    }
                }
            },
            namespace: {
                Default: "",
                type: String
            },
        },

        watch: { 
            "settings": {
                deep: true,
                handler: function (newValue) {
                    this.input_value = newValue.value
                }

            }
        },
        computed: {
            value: {
                get() { 
                    return this.input_value
                },
                set(newValue) {
                    this.input_value = newValue
                    this.setValue(this.input_value)
                }
            },
            mainClass: {
                get() {
                    return this.st('class_styles') + 
                        ' input-component ' + 
                        (this.st('required') && this.st('label') != '' ? 'required' : '') + 
                        ' input_' + this.st('name') +
                        (this.st('label') == '' ? ' no-label ' : '')
                },
            }
        },
        
        methods: {
            ...mapMutations({
                setValue (commit, payload) {

                    if (this.st('array_key') > 0 ) {
                        return commit(this.namespace + '/mutation_' + this.st('mutation'), {"data": payload, "array_key": this.st('array_key')})
                    } else {
                        return commit(this.namespace + '/mutation_' + this.st('mutation'), payload)
                    }
                }
            }),
            ...mapActions({
                show_message(dispatch, message) {
                    return dispatch("ModalData/show", message, { root: true })
                }
            }),
            st(field) {
                let vm = this
                try {
                    let response = field in vm.settings ?
                        vm.settings[field] :
                        ''

                    return response
                } catch (error) {
                    return ''
                }
            },
            search(event) {
                let id = 'search-selectID_' + this.st('name')
                let max_to_show = 5
                let count = 0
                let search_value = ''
                if ( this.st('no_spacing') ) {
                    event.target.value = event.target.value.replace(/\s/g, '')
                }
                if ( event.target.name.indexOf('domain') != -1 ) {
                        //event.target.value = event.target.value.replace(/@/g, '');
                }
                if ( event.target.name.indexOf('lada') != -1 ) {
                    event.target.value = event.target.value.replace(/[^0-9+]/g, "")
                }
                
                if (event.target.nodeName != "LI") {
                    RegExp.quote = function(str) {
                        return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                    };
                    search_value = new RegExp(RegExp.quote(event.target.value))
                }
                

                for (let node of document.getElementById(id).childNodes) {
                    if ( node.innerText !== undefined ) {
                        if ( 
                            event.target.nodeName == "LI" ||
                            count == max_to_show || 
                            event.target.value.trim() == "" ||
                            node.innerText.search(new RegExp(search_value, "i") ) < 0
                        ) {
                            node.style.display = "none"
                        } else {
                            node.style.display = "block"
                            count ++
                        }
                    }
                }
            },
            open_search(event) {
                let id = 'search-selectID_' + this.st('name')
                let max_to_show = 6
                let count = 0
                let search_value = this.value
                RegExp.quote = function(str) {
                    return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                };
                search_value = new RegExp(RegExp.quote(event.target.value))

                for (let node of document.getElementById(id).childNodes) {
                    if ( node.innerText !== undefined ) {
                        if ( 
                            count == max_to_show || 
                            node.innerText.search(new RegExp(search_value, "i") ) < 0
                        ) {
                            node.style.display = "none"
                        } else {
                            node.style.display = "block"
                            count ++
                        }
                    }
                }
            },
            close_search() {
                let vm = this
                setTimeout( function() {
                    if ( vm.st('search_type') == 'lada' && document.getElementById('inputID_' + vm.st('name')).value.length != 0 && (document.getElementById('inputID_' + vm.st('name')).value).indexOf("+") != 0) {
                        document.getElementById('inputID_' + vm.st('name')).value = "+" + document.getElementById('inputID_' + vm.st('name')).value
                    }
                    if ( vm.st('search_type') == 'domain' && document.getElementById('inputID_' + vm.st('name')).value.length != 0 && (document.getElementById('inputID_' + vm.st('name')).value).indexOf("@") == 0 ) {
                        document.getElementById('inputID_' + vm.st('name')).value = document.getElementById('inputID_' + vm.st('name')).value
                    }

                    let current_option = vm.st('options').find((option) => { return option.name == document.getElementById('inputID_' + vm.st('name')).value } )
                    let id = 'search-selectID_' + vm.st('name')

                    if ( current_option == undefined  ) {
                        vm.value = document.getElementById('inputID_' + vm.st('name')).value
                    } else if (vm.value != current_option.value) {
                        vm.value = current_option.value
                    }

                    for (let node of document.getElementById(id).childNodes) {
                        if ( node.innerText !== undefined ) {
                            node.style.display = "none"
                        }
                    }
                }, 200)

            },
            selected_option(event, object) {
                document.getElementById('inputID_' + this.st('name')).value = object.name
                this.value = object.value
            },
            gt_max_length() {
                return this.st('max_length') != '' ? this.st('max_length') : this.default_max_length
            },
            gt_type() {
                return this.st('type') != 'number' || this.st('type') != 'email'  ? this.st('type') : 'text'
            },
            verify_regex(input) {
                let vm = this
                let regex = /(<([^>]+)>)/ig;
                var input_val = input.target.value

                input_val = input_val.replace(regex, "")
                vm.value = input_val

                
                
                if ( vm.st('no_spacing') ) {
                    input_val = input_val.replace(/\s/g, '')
                    vm.value = input_val
                }
                
                if (vm.st('type') == "tel") {
                    input_val = input_val.replace(/[^0-9]/g, "")
                    vm.value = input_val
                } else {
                    if (vm.st('type') == 'text' && input.target.name.indexOf('email') == -1 && input.target.name.indexOf('curp') == -1 && input.target.name.indexOf('clabe') == -1 && input.target.name.indexOf('validation') == -1) {
                        input_val = input_val.replace(/[^a-zA-Z ]/g, '')
                        vm.value = input_val
                    }
                    if (vm.st('type') == 'text' && (input.target.name.indexOf('curp') != -1) ) {
                        input_val = input_val.replace(/[^a-zA-Z0-9]/g, '')
                        vm.value = input_val
                    }
                }
                if (input_val > vm.gt_max_length()) {
                    vm.value = input_val.substring(0,vm.gt_max_length() )
                }
            },

            onChangeFile(event) {
                if ( event.target.files.length == 0 ) {
                    return 0
                }
                
                if (event.target.files[0].type != 'application/pdf') {
                    event.target.value = ""
                    this.show_message("Sólo se permiten archivos PDF")
                }else if (event.target.files[0].size / 1024 / 1024 <= 2) {
                    this.input_value = event.target.files[0]
                    this.setValue(event.target.files[0])
                } else {
                    event.target.value = ""
                    this.show_message("El tamaño del archivo no debe exceder 2MB")
                }
                
            },
        },

        mounted() {
            this.loaded = true
            this.value = this.settings.value
          
        }
    }
</script>