<template>
  <div class="a-activity-indicator m-auto" aria-live="off">
    <div class="a-activity-indicator__text">
      Estamos procesando tu solicitud... Por favor no refresques el sitio.
    </div>
    <div class="a-activity-indicator__top-box"></div>
    <div class="a-activity-indicator__bottom-box"></div>
    <br>
    
  </div>
</template>

<style lang="scss">
  @import "../assets/styles/components/_activityIndicatorComponent.scss";
</style>