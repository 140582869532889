<template>
  <div v-show="st('show_modal')" class="ptu_exassociates_modal">
    <div :class="'ptu_exassociates_modal-content'" >
      <div class="ptu_exassociates_modal-detail" v-html="st('content')" />
      <div class="ptu_exassociates_modal-close" @click="mt('hiddenOnClick')">
        Cerrar
      </div>   
    </div>
  </div>
</template>

<style lang="scss">
  @import "../assets/styles/components/_modalComponent.scss";
</style>
    
<script>

  export default {
    data() {
      return {
        storeRoute: "ModalData"
      }
    },
      mounted() {
        let vm = this

        let loop_interval = setInterval(() => {
          vm.ac('load_message')
          clearInterval(loop_interval)
        }, 1000);
      }
  }
</script>