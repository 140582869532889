import axios from "axios";
import { useCookies } from "vue3-cookies"

/* eslint-disable */

export default {
    namespaced: true,
    state: () => ({
        headers: {
            "Authorization": "",
            'Content-Type': 'multipart/form-data'
        },
        login_success: false
    }),

    getters: {
        getToken(state) {
            const { cookies } = useCookies();
            return cookies.get('_token')
        },
    },

    mutations: {
        mutation_Token(state, access) {
            const { cookies } = useCookies();

            document.cookie = "_token=" + access + (document.location.protocol == 'https:' ? ";secure" : "");
            state.headers.Authorization = "Bearer " + access
        },
        mutation_Refresh(state, refresh) {
            document.cookie = "_refresh=" + refresh + (document.location.protocol == 'https:' ? ";secure" : "");
        },
        mutation_Period(state, value) {
            // console.log('token')
            document.cookie = "_period_enabled=" + value + (document.location.protocol == 'https:' ? ";secure" : "");
            // console.log(document.cookie)
        },
        mutation_MaxLogginsTries(state) {
            const { cookies } = useCookies();
            var current_tries = cookies.get('_max_loggin_tries')
            var value = (cookies.isKey('_max_loggin_tries') ? parseInt(current_tries) : 0) + 1

            var now = new Date();
            var time = now.getTime();
            var expireTime = time + 1000 * 60 * parseInt(process.env.VUE_APP_MAX_TRY_LOGIN_BAN_MINUTES);
            now.setTime(expireTime);
            document.cookie = "_max_loggin_tries=" + value + (document.location.protocol == 'https:' ? ";secure" : "") + ";expires=" + now.toUTCString() + ";";
        },
        setInitialized(state, value) {
            // console.log(state)
            state.initialized = value;
            // console.log('VALUE', state.initialized)
        }
    },

    actions: {
        async initializeApp({ commit, dispatch }) {
            
            try {
                await dispatch('__validate_period');
                // Otras operaciones asincrónicas...
                commit('setInitialized', true);
            } catch (error) {
                console.error('Failed to initialize:', error);
                commit('setInitialized', false);
            }
        },
        async __credentials({ commit, dispatch, state }, parameters) {
            try {
                const { cookies } = useCookies();
                commit('app_loaded_set', false, { root: true })

                if (cookies.isKey('_max_loggin_tries') && cookies.get('_max_loggin_tries') >= parseInt(process.env.VUE_APP_MAX_TRY_LOGIN)) {
                    dispatch("ModalData/show_max_try_error", "", { root: true })
                    state.login_success = false
                    return false
                }

                const url = process.env.VUE_APP_API_TOKEN;
                const response = await axios.post(url, parameters.data);

                if (response.status == 200) {
                    if ('success' in response.data && response.data.success != "true") {
                        dispatch("ModalData/show", "", { root: true })
                        dispatch("LoginData/login_error", "", { root: true })
                        commit('app_loaded_set', true, { root: true })
                        state.login_success = false
                    } else {
                        commit("mutation_Token", response.data.access)
                        commit("mutation_Refresh", response.data.refresh)
                        dispatch("LoginData/remove_login_error", "", { root: true })
                        state.login_success = true
                        location.reload()
                    }
                    return true
                } else {
                    // console.log('Data code response:' + response.status)
                    commit('app_loaded_set', true, { root: true })
                    state.login_success = false
                }
            } catch (error) {
                try {
                    dispatch("ModalData/warning", error.response.data.detail, { root: true })
                } catch (error) {
                    dispatch("AlertData/error", "Error when try call token auth", { root: true })
                }
                state.login_success = false
                commit('app_loaded_set', true, { root: true })
            }
            return false
        },

        async __validate_credentials({ commit }) {
            const { cookies } = useCookies();
            try {
                if (!cookies.isKey("_refresh")) {
                    return true
                }

                const url = process.env.VUE_APP_API_TOKEN + "refresh/";
                commit("mutation_Token", cookies.get("_token"))
                const response = await axios.post(url, { "refresh": cookies.get("_refresh") });

                if (response.status == 200) {
                    if ('code' in response.data && response.data.code == 500) {
                        cookies.remove('_refresh')
                        cookies.remove('_token')
                        dispatch("AlertData/warning", "No pudimos procesar tu solicitud", { root: true })
                    } else {
                        commit("mutation_Token", response.data.access)
                    }
                    return true
                } else {
                    console.log('Data code response:' + response.status)
                }

            } catch (error) {
                cookies.remove('_refresh')
                cookies.remove('_token')
                commit('rt_redirect', 'login', { root: true })
            }
            return false
        },

        async __validate_period({ commit }) {
            // console.log('VALI')
            const { cookies } = useCookies();
            try {
                /*if (!cookies.isKey("_refresh")) {
                    return true
                }*/

                const url = process.env.VUE_APP_API_URL + "associates/period/is_enabled/";
                const response = await axios.get(url);
                if (response.status == 200) {
                    // console.log('200')
                    commit("mutation_Period", response.data.period_active)
                    if (!response.data.period_active) {
                        cookies.remove('mutation_Period')
                        commit('rt_redirect', 'disable', { root: true })
                    } else {
                        return true
                    }
                } else {
                    // console.log('REMOVE')
                    cookies.remove('mutation_Period')
                    commit('rt_redirect', 'disable', { root: true })
                }

            } catch (error) {
                cookies.remove('mutation_Period')
                commit('rt_redirect', 'disable', { root: true })
            }
            return false
        },

        async __request_gt({ commit, state, dispatch }, parameters) {
            try {
                if (parameters.endpoint.data_count != 0) {
                    return parameters.endpoint.data
                }
                const url = process.env.VUE_APP_API_URL + parameters.endpoint.endpoint;
                const config = { headers: state.headers };

                const response = await axios.get(url, config);

                if (response.status == 200) {
                    if (!!response.data.error) {
                        dispatch("AlertData/warning", response.data.error, { root: true })
                        return 0
                    }

                    if ("mutation" in parameters && parameters.mutation.length != 0) {
                        parameters.mutation.forEach(mutation => {
                            commit(parameters.endpoint.mutation, response.data.data, { root: true })
                            commit(mutation, parameters.endpoint.data, { root: true })
                        });
                    } else {
                        commit(parameters.endpoint.mutation, response.data.data, { root: true })
                    }
                } else {}

            } catch (error) {
                console.log("Error when try call " + process.env.VUE_APP_API_URL + parameters.endpoint.endpoint)
                try {
                    dispatch("AlertData/warning", error.response.data.error, { root: true })
                } catch (error) {
                    dispatch("AlertData/error", "Something it's wrong", { root: true })
                }
            }
        },

        async __request_post({ commit, state, dispatch }, parameters) {
            let url = process.env.VUE_APP_API_URL + parameters.endpoint.endpoint;
            try {
                const config = { headers: state.headers };

                const response = await axios.post(url, parameters.endpoint.data, config);

                if (response.status == 200) {
                    if (!!response.data.error) {
                        dispatch("AlertData/warning", response.data.error, { root: true })
                        return 0
                    }

                    if ("mutation" in parameters && parameters.mutation.length != 0) {
                        parameters.mutation.forEach(mutation => {
                            commit(parameters.endpoint.mutation, response.data.data, { root: true })
                            commit(mutation, parameters.endpoint.data, { root: true })
                        });
                    } else {
                        commit(parameters.endpoint.mutation, response.data.data, { root: true })
                    }
                } else {}

            } catch (error) {
                console.log("Error when try call " + url)
                try {
                    dispatch("AlertData/warning", error.response.data.error, { root: true })
                } catch (error) {
                    dispatch("AlertData/error", "Something it's wrong", { root: true })
                }
            }
        },

        async __request_update({ commit, state, dispatch }, parameters) {
            let url = process.env.VUE_APP_API_URL + parameters.endpoint.endpoint;
            try {
                const config = { headers: state.headers };

                const response = await axios.put(url, parameters.endpoint.data, config);

                if (response.status == 200) {
                    if (!!response.data.error) {
                        dispatch("AlertData/warning", response.data.error, { root: true })
                        return 0
                    }

                    if ("mutation" in parameters && parameters.mutation.length != 0) {
                        parameters.mutation.forEach(mutation => {
                            commit(parameters.endpoint.mutation, response.data.data, { root: true })
                            commit(mutation, parameters.endpoint.data, { root: true })
                        });
                    } else {
                        commit(parameters.endpoint.mutation, response.data.data, { root: true })
                    }
                } else {}

            } catch (error) {
                console.log("Error when try call " + url)
                if ("fetch_response" in parameters.endpoint) {
                    commit(parameters.endpoint.fetch_response, error.response.status, { root: true })
                }
                try {
                    dispatch("AlertData/warning", error.response.data.status != '' ? error.response.data.status : error.response.data.error, { root: true })
                } catch (error) {
                    dispatch("AlertData/error", "Something it's wrong", { root: true })
                }
            }
        },

        async __request_update_files({ commit, state, dispatch }, parameters) {
            let url = process.env.VUE_APP_API_URL + parameters.endpoint.endpoint;
            try {
                const config = { headers: state.headers };
                console.log(parameters)

                const response = await axios.post(url, parameters.endpoint.data, config);
                

                if (response.status == 200) {
                    if (!!response.data.error) {
                        dispatch("AlertData/warning", response.data.error, { root: true })
                        return 0
                    }

                    if ("mutation" in parameters && parameters.mutation.length != 0) {
                        parameters.mutation.forEach(mutation => {
                            commit(parameters.endpoint.mutation, response.data.data, { root: true })
                            commit(mutation, parameters.endpoint.data, { root: true })
                            console.log('ENTERED')
                        });
                    } else {
                        console.log(parameters.endpoint.mutation, response.data.data)
                        commit(parameters.endpoint.mutation, response.data.data, { root: true })
                        console.log('SI')
                    }
                    return response
                } else {
                    console.log('ERRRORRR')
                    dispatch("ModalData/show", "", { root: true })
                }

            } catch (error) {
                console.log("Error when try call " + url)
                dispatch("ModalData/show_documentation_error", "", { root: true })
                try {
                    dispatch("AlertData/warning", error.response.data.error, { root: true })
                } catch (error) {
                    dispatch("AlertData/error", "Something it's wrong", { root: true })
                }
            }
        },

    }
}