export default {
    namespaced: true,
    state: () => ({
        title: "¿Necesitas ayuda?",
        content: "Puedes contactarnos y con gusto resolveremos tus dudas.",
        email: "ptuex.asociado@mx.bosch.com",
    }),
    mutations: {},
    actions: {},
    getters: {}
}