export default {
    namespaced: true,
    state: () => ({
        data_loaded: false,
        meta_data: {
            title: 'sitio no disponible'
        },
        title: "Estimado ex asociado de Bosch",
        content: "Estimado Ex asociado.\n\n" +
            "Te informamos que la pagina de utilidades esta presentando un alto número de solicitudes, favor de intentarlo más tarde.",
        elements: []
    }),
    getters: {}
}