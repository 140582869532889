<template>
  <div v-if="$store.state.app_loaded" id="ptu_exassociates-login" class="ptu_exassociates_login d-lg-flex d-block w-100">
    <img alt="bosch navbar" class="d-sm-block d-block d-lg-none bosch-navbar" src="/images/bosch_bar.svg">
    <img class="ptu_exassociates_login-background d-lg-block d-none" src="/images/login_background.svg" alt="login_background">

    <div class="ptu_exassociates_login-content d-md-block w-100">
      <div class="d-table my-4 mr-md-auto ml-md-auto">
        <img src="/images/logo.svg" alt="logo">
      </div>

      <div class="d-block mt-2">
        <div class="font-22 text-bold mb-3">{{$store.state.application + ' ' + $store.state.company}}</div>
        <div class="mb-4">{{st('welcome_text_one')}}</div>
        <div class="mb-4">{{st('welcome_text_two')}}</div>
        <InputComponent
          v-for="input in st('form')" :key="'input_' + input.name"
          class="mb-4"  
          :namespace="storeRoute"
          :settings="input"
        />

        <ButtonComponent class="mb-4"
          :name="'submit'"
          :label="'COMENZAR'"
          @click="ac('login')"
          :enable="true"
        />
      </div>
      
    </div>

  </div>
</template>
    
<script>
  import InputComponent from '../components/InputComponent.vue'
  import ButtonComponent from '../components/ButtonComponent.vue'

  export default {
    props: {
      storeRoute: {
        require: true,
        Type: String
      }
    },
    components: { 
      InputComponent,
      ButtonComponent

    }
  }
</script>
<style lang="scss">
  @import "../assets/styles/views/_loginView.scss";
</style>