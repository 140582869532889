export default {
    namespaced: true,
    state: () => ({
        meta_data: {
            title: "registro de datos",
        },
        title: "Documentación pendiente",
        text_one: "Para completar tu proceso de PTU. Es necesario que nos reenvies la siguiente documentación:",
        show_form: false,
        form_sended: false,
        form: {
            'familiar_data': [{
                    'label': 'INE del familiar (.pdf max 2mb)',
                    'name': 'familiar_ine',
                    'type': 'file',
                    'required': true,
                    'value': '',
                    'mutation': "FamiliarIne",
                },

            ],
            'foreign_form': [{
                'label': 'Comprobante de domicilio (.pdf max 2mb)',
                'name': 'file_aditional_1',
                'type': 'file',
                'required': true,
                'value': '',
                'mutation': "AddressFile",
            }, ],
            'general_content_cfdi': [{
                'label': 'Constancia de situación fiscal (.pdf max 2mb)',
                'name': 'cfdi',
                'type': 'file',
                'required': true,
                'value': '',
                'mutation': "CFDI",
            }],
        },
        api_data: {
            associate: {
                data: [],
                endpoint: 'associates/associate/logged/',
                data_count: 0,
                mutation: 'PendingDocumentsData/mutationAPI_AssociateLogged',
            },
            handle_files: {
                data: {},
                endpoint: 'associates/associate/save_documents/',
                mutation: '',
            },
        }
    }),
    mutations: {
        mutation_FamiliarIne(state, newValue) {
            let key = 'familiar_ine'
            state.form.familiar_data.find(function(element) {
                if (element['name'] == key) {
                    element.value = newValue
                }
            });
        },

        mutation_AddressFile(state, newValue) {
            let key = 'file_aditional_1'
            state.form.foreign_form.find(function(element) {
                if (element['name'] == key) {
                    element.value = newValue
                }
            });
        },
        mutation_CFDI(state, newValue) {
            let key = 'cfdi'
            state.form.general_content_cfdi.find(function(element) {
                if (element['name'] == key) {
                    element.value = newValue
                }
            });
        },
        mutationAPI_AssociateLogged(state, data) {
            state.api_data.associate.data = data
            state.api_data.associate.data_count = data.length
        },
        meta(state, metadata) {
            state.meta_data[metadata];
        }
    },
    actions: {
        async getAssociate({ state, dispatch }) {
            dispatch('AxiosVuex/__request_gt', {
                endpoint: state.api_data.associate,
                mutation: []
            }, { root: true }).then(() => {
                state.show_form = true
                this._state.data.app_loaded = true
            })
        },
        async send_documents({ state, commit, dispatch }) {
            state.api_data.handle_files.data = new FormData()
            let current_state = state.api_data.associate.data
            commit('validateFields', { "namespace": "PendingDocumentsData", "state": "general_content_cfdi" }, { root: true })
            if (state.form_error) {
                return false
            }

            if (current_state.is_foreign == 1) {
                commit('validateFields', { "namespace": "PendingDocumentsData", "state": "foreign_form" }, { root: true })
                if (state.form_error) {
                    return false
                }
            }

            if (current_state.is_representative == 1) {
                commit('validateFields', { "namespace": "PendingDocumentsData", "state": "familiar_data" }, { root: true })
                if (state.form_error) {
                    return false
                }
            }

            state.api_data.handle_files.data.append('cfdi', state.form.general_content_cfdi[0].value)

            if (current_state.is_foreign == 1) {
                state.api_data.handle_files.data.append('address', state.form.foreign_form[0].value)
            } else {
                state.api_data.handle_files.data.delete('address')
            }

            if (current_state.is_representative == 1) {
                state.api_data.handle_files.data.append('representative_ine', state.form.familiar_data[0].value)
            } else {
                state.api_data.handle_files.data.delete('representative_ine')
            }
            try {
                dispatch('AxiosVuex/__request_update_files', {
                    endpoint: state.api_data.handle_files,
                    mutation: []
                }, { root: true }).then(() => {
                    commit('rt_redirect', 'progress', { root: true })
                })
            } catch (e) {
                state.form_sended = false
                state.show_form = true
            }
        }
    }
}