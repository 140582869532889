export default {
    namespaced: true,
    state: () => ({
        data_loaded: false,
        meta_data: {
            title: 'Sitio no disponible'
        },
        title: "Estimado ex asociado de Bosch",
        content: "",
        elements: [],
        api_data: {
            handle_request: {
                data: { 'name': "Plataforma inactiva" },
                endpoint: 'catalogs/messages/single/',
                data_count: 0,
                mutation: 'DisableData/mutation_AddContent',
            },
        }
    }),
    mutations: {
        mutation_AddContent(state, data) {
            state.content = data.message
            state.data_loaded = true
        },
    },
    actions: {
        load_message({ state, dispatch }) {
            dispatch('AxiosVuex/__request_post', {
                endpoint: state.api_data.handle_request,
                mutation: []
            }, { root: true })
        }
    },
    getters: {}
}