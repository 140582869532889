<template>
  <div id="app">
    <AlertComponent/>
    <ModalComponent/>
    
    <router-view/>
    <div v-if="!$store.state.app_loaded" class="main-activity-loaded">
      <ActivityIndicatorComponent/>
    </div>
  </div>
</template>

<script>
  import AlertComponent from './components/AlertComponent.vue'
  import ModalComponent from './components/ModalComponent.vue'
  import ActivityIndicatorComponent from './components/ActivityIndicatorComponent.vue'

  export default {
    components: { 
      AlertComponent,
      ModalComponent,
      ActivityIndicatorComponent
    }
  }
</script>

<style lang="scss">
  @import "./assets/styles/main.scss";
</style>