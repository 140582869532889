<template>
  <div id="ptu_exassociates-navbar">
    <img alt="bosch navbar" class="d-flex bosch-navbar" src="/images/bosch_bar.svg">
    <nav class="navbar navbar-expand-lg">
      
      <a class="navbar-brand" @click.prevent="ac('home')">
        <img alt="bosch logo" class="bosch-logo c-pointer" src="/images/logo.svg">
      </a>
    
      <div class="ml-auto text-bold mr-2 font-24 d-none d-md-block">
        {{ $store.state.application }}
        <br/>
        
      </div>
      
    </nav>
  </div>
</template>

<script>
  export default {
    props: {
      storeRoute: {
        default: "NavbarBoschData",
        Type: String
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/styles/components/_navbarBoschComponent.scss";
</style>