<template>
  <div v-show="$store.state.app_loaded">
    <NavbarBoschComponent/>
    <StatusComponent/>
    
    <div class="ptu_exassociates_progress" id="ptu_exassociates_progress">
      <div class="row">
        
          <div class="col-md-12 mb-4">
            <div class="ptu_exassociates_progress-title">{{st("title")}}</div>
            <div v-if="st('data_loaded') && st('step') == 5" class="ptu_exassociates_progress-content text-bold">
              Si has ingresado aquí por que se te solicitó volver a subir tu documentación. 
            </div>
            <div class="ptu_exassociates_progress-subtitle">{{st("subtitle")}}</div>
            <div class="ptu_exassociates_progress-content" v-html="st('content')"/>
            
            <div class="ptu_exassociates_progress-image">
              <ActivityIndicatorComponent v-if="!st('data_loaded')"/>
              <img v-else alt="status image" :src="st('icon')">
              
              <div v-if="st('data_loaded') && st('step') == 5" class="col-lg-12 mb-4">
                <div class="ptu_exassociates_form-title font-24 text-center mb-2 mt-3">
                  <router-link to="/ptu-exasociados-documentacion-pendiente">
                    <ButtonComponent class="mb-4"
                    :name="''"
                    :label="'Actualizar documentos'"
                    :enable="true"
                    /> 
                    
                  </router-link>

                </div>
              </div>

            </div>
              <div class="ptu_exassociates_progress-updated" v-html="st('updated_at')"/>
          </div>

          <NeedHelpComponent/>

      </div>
    </div>

    <FooterComponent/>
  </div>
</template>
  
  <script>
  import NavbarBoschComponent from '../components/NavbarBoschComponent.vue'
  import StatusComponent from '../components/StatusComponent.vue'
  import FooterComponent from '../components/FooterComponent.vue'
  import NeedHelpComponent from '../components/NeedHelpComponent.vue'
  import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent.vue'
  import ButtonComponent from '@/components/ButtonComponent.vue'
  
  export default {
    props: {
      storeRoute: {
        require: true,
        Type: String
      }
    },
    components: {
      NavbarBoschComponent,
      FooterComponent,
      StatusComponent,
      NeedHelpComponent,
      ActivityIndicatorComponent,
      ButtonComponent
    },
    mounted() {
      let vm = this;
      vm.loadInitialData();
    },
    methods: {
      async loadInitialData() {
        try {
          this.$store.dispatch('ProgressData/getStatusContent');
        } catch (error) {
          console.error('Error loading initial data:', error);
        }
      }
    }
  }
  </script>
  <style lang="scss">
    @import "../assets/styles/views/_progressView.scss";
  </style>