<template>
  <div id="ptu_exassociates-footer">
    <div class="m-auto">
      {{$store.state.footer}}
    </div>
  </div>
</template>

<style lang="scss">
  @import "../assets/styles/components/_footerComponent.scss";
</style>