import { useCookies } from "vue3-cookies"

export default {
    namespaced: true,
    state: () => ({
        meta_data: {
            title: "inicio de sesión"
        },
        welcome_text_one: "Bienvenido al proceso de Pago de Utilidades para Ex-Asociados Bosch.",
        welcome_text_two: "Ingresa los datos para validar tu estatus.",
        form_error: false,
        form_router_name: 'form-exasociados',
        form: [{
                'label': 'Últimos 4 dígitos del CURP',
                'name': 'last_4_curp',
                'type': 'text',
                'value': '',
                'required': true,
                'max_length': "4",
                'mutation': "Last4Curp",
            },
            {
                'label': 'Fecha de nacimiento',
                'name': 'birthday',
                'type': 'date',
                'value': '',
                'required': true,
                'mutation': "Birthday",
            }
        ]
    }),
    mutations: {
        mutation_Last4Curp(state, newValue) {
            let key = 'last_4_curp'
            state.form.find(function(element) {
                if (element['name'] == key) {
                    element.value = newValue.toUpperCase()
                }
            });
        },
        mutation_Birthday(state, newValue) {
            let key = 'birthday'
            state.form.find(function(element) {
                if (element['name'] == key) {
                    element.value = newValue
                }
            });
        }
    },
    actions: {
        async login({ state, dispatch, commit }) {
            commit('validateFields', { "namespace": "LoginData" }, { root: true })
            if (state.form_error) {
                return false
            }

            let birthday = state.form[1].value.split("-")

            let day = birthday[2]
            let month = birthday[1]
            let year = birthday[0]

            year = year.substring(2)

            dispatch('AxiosVuex/__credentials', {
                data: {
                    'email': state.form[0].value,
                    'password': year + month + day,
                }
            }, { root: true })
            if (this.state.AxiosVuex.login_success) {
                await commit('rt_redirect', state.form_router_name, { root: true })
            }
        },
        login_error({ commit }) {
            commit('AxiosVuex/mutation_MaxLogginsTries', '', { root: true })
        },
        remove_login_error() {
            const { cookies } = useCookies();
            cookies.remove('_max_loggin_tries')
        },
        /*load_period({state, dispatch}) {
            dispatch('AxiosVuex/__validate_period', {
                endpoint: state.api_data.handle_request,
                mutation: []
            }, { root: true })
        },*/
    }
}