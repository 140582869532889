const Mixins = {
    data() {
        return {
            loaded: false
        }
    },

    beforeMount() {
        let vm = this
        if (vm.storeRoute) {

            if (process.env.VUE_APP_MAINTENANCE_MODE == "1") {
                this.loaded = true
                this.$store.state.app_loaded = true
            } else if (vm.meta('title')) {
                vm.loaded = false
                vm.check_period_enabled()
                vm.refresh_tkn().then(() => {
                    setInterval(function() {
                        vm.refresh_tkn()
                    }, 1000 * 60 * 25)
                    var counter = 4
                    let loop_interval = setInterval(() => {
                        if (counter == 0) {
                            this.setLoaded()
                            clearInterval(loop_interval)
                        } else {
                            counter = counter - 1
                        }
                    }, 1000);
                })
            }
        }

    },
    mounted() {
        if (this.storeRoute) {
            document.title = this.$store.state.application

            if (this.meta('title')) {
                document.title += ' - ' + this.meta('title')
            }
        }
    },
    methods: {
        setLoaded() {
            this.loaded = true
            this.$store.state.app_loaded = true
        },
        st(variable) {
            return this.$store.state[this.storeRoute][variable]
        },
        gt(getter, value = null) {
            try {
                return this.$store.getters[this.storeRoute + '/' + getter](value)
            } catch (error) {
                return this.$store.getters[this.storeRoute + '/' + getter]
            }
        },
        meta(field) {
            try {
                return this.$store.state[this.storeRoute].meta_data[field]
            } catch (error) {
                return ""
            }
        },
        setMeta(variable, value) {
            this.$store.state[this.storeRoute][variable] = value
        },
        async ac(action, parameters = null) {
            return this.$store.dispatch(this.storeRoute + '/' + action, parameters)
        },
        mt(mutation, value) {
            this.$store.commit(this.storeRoute + '/mutation_' + mutation, value)
        },
        async refresh_tkn() {
            this.$store.dispatch('AxiosVuex/__validate_credentials')
            await this.setLoaded()
        },
        async check_period_enabled() {
            this.$store.dispatch('AxiosVuex/__validate_period')
            await this.setLoaded()
        },
        async outsystems_ac(action, parameters = null) {
            return this.$store.dispatch('AxiosVuexOutsystems/' + action, parameters)
        },
    },
}

export default Mixins