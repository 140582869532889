import { useCookies } from "vue3-cookies"

export default {
    namespaced: true,
    state: () => ({}),
    mutations: {
        removeLocalSession() {
            const { cookies } = useCookies();
            cookies.remove('_refresh')
            cookies.remove('_token')
            // cookies.remove('_period_enabled')
        }
    },
    actions: {
        async logout({ commit }) {
            commit("removeLocalSession")
            location.href = "/"
        },
        async home() {
            location.href = "/"
        },
    },
    getters: {}
}