<template>
    <div>
      <NavbarBoschComponent/>
      
      <div class="ptu_exassociates_disable" id="ptu_exassociates_disable">
        <div class="row">
          
            <div class="col-md-12 mb-4">
                <div class="ptu_exassociates_disable-title font-24 text-bold">{{st("title")}}</div>
                <div class="ptu_exassociates_disable-content" v-html="st('content')"/>
            </div>

            <NeedHelpComponent/>

        </div>
      </div>

      <FooterComponent/>
    </div>
  </template>
    
    <script>
    import NavbarBoschComponent from '../components/NavbarBoschComponent.vue'
    import FooterComponent from '../components/FooterComponent.vue'
    import NeedHelpComponent from '../components/NeedHelpComponent.vue'
    
    export default {
      props: {
        storeRoute: {
          require: true,
          Type: String
        }
      },
      components: {
        NavbarBoschComponent,
        FooterComponent,
        NeedHelpComponent,
      },
    }
    </script>
    <style lang="scss">
      @import "../assets/styles/views/_temporalView.scss";
    </style>