export default {
    namespaced: true,
    state: () => ({
        data_loaded: false,
        title: "Proceso PTU para Ex-Asociados Bosch",
        main_dir: "/images",
        icons_dir: "/icons",
        elements: [],
        api_data: {
            status_list: {
                data: [],
                endpoint: 'catalogs/register_status/',
                data_count: 0,
                mutation: 'StatusBarData/mutationAPI_SetStatusList',
                called: false
            },
        }

    }),
    mutations: {
        mutationAPI_SetStatusList(state, data) {
            state.elements = data
            state.data_loaded = true
            this.commit('StatusBarData/mutation_SetStepEnabled', 1)
        },
        mutation_SetStepEnabled(state, current_step) {
            let ignore_steps = [5,6]
            if (current_step != 0) {
                state.elements.map(function(element) {
                    if (element.step < current_step && ignore_steps.indexOf(current_step) == -1) {
                        element.image = 'status_check.svg'
                    }
                    element.active = element.step <= current_step && ignore_steps.indexOf(current_step) == -1 || (ignore_steps.indexOf(current_step) != -1 && (element.step == 1 || (element.step == 2 && current_step == 5) || (element.step == 2 && current_step == 6)));
                    if (current_step == 5 && element.step == 2) {
                        element.name = "Documentación Rechazada";
                    }
                    if (current_step == 6 && element.step == 2) {
                        element.name = "Documentación en proceso";
                    }

                });
            }
        },
    },
    actions: {
        async getStatusList({ state, dispatch }) {
            await dispatch('PendingDocumentsData/getAssociate', null, { root: true });
            if (!state.api_data.status_list.called) {
                state.api_data.status_list.called = true
                dispatch('AxiosVuex/__request_gt', {
                    endpoint: state.api_data.status_list,
                    mutation: []
                }, { root: true })
            }
        },
    },
    getters: {
        getImageByKeyElement: (state) => (key) => {
            return "active" in state.elements[key] ?
                (
                    state.main_dir +
                    state.icons_dir + '/' +
                    (state.elements[key].active ? 'white' : 'black') +
                    '/' + state.elements[key].image
                ) : ""
        },

    }
}