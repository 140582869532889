<template>
    <div id="ptu_exassociates-need_help" class="ptu_exassociates_need_help d-lg-flex d-block">
        
        <div class="ptu_exassociates_need_help-image">
          <img alt="need help" src="/images/need_help_user.svg">
        </div>

        <div class="ptu_exassociates_need_help-content mx-auto">
          <div class="ptu_exassociates_need_help-title">
            {{st("title")}}
          </div>
          {{st("content")}}
          <div class="ptu_exassociates_need_help-email">Email: <a :href="'mailto:' + st('email')" target="_blank">{{ st("email") }}</a></div>
        </div>
        
    </div>
  </template>
    
  <script>
    export default {
      props: {
        storeRoute: {
          default: "NeedHelpData",
          Type: String
        }
      }
    }
  </script>
  <style lang="scss">
    @import "../assets/styles/components/_needhelpComponent.scss";
  </style>