<template>
    <div id="ptu_exassociates-status" class="ptu_exassociates_status mb-4 pt-4 pb-4 d-none d-lg-block" v-if="st('data_loaded')">
        
        <div class="ptu_exassociates_status-title mx-auto font-24 text-center text-bold">
            {{st('title')}}
        </div>
        
        <ul class="ptu_exassociates_status-steps d-block mx-auto my-4 text-center">
            <li v-for="(element, key) in st('elements')" :key="'step-' + key" :class="'step text-center position-relative d-inline-block' + ( !!element.active ? ' step-active' : '' )">
                <div class="step-content">
                    <img :alt="'step-' + key" class="step-content-image" :src="gt('getImageByKeyElement', key)">
                </div>
                <span class="step-content-label font-12">{{element.name}}</span>
            </li>
        </ul>
    
    </div>
  </template>
    
  <script>
    export default {
      props: {
        storeRoute: {
          default: "StatusBarData",
          Type: String
        }
      },
      mounted() {
        let vm = this
        setTimeout(() => {
          vm.ac('getStatusList')
        }, 100);
      }
    }
  </script>
  <style lang="scss">
    @import "../assets/styles/components/_statusComponent.scss";
  </style>