<template>
    <div v-show="$store.state.app_loaded">
      <NavbarBoschComponentNoAuth/>
      
      <div class="ptu_exassociates_disable" id="ptu_exassociates_disable">
        <div class="row">
          
            <div class="col-md-12 mb-4">
                <div v-if="st('data_loaded')" class="ptu_exassociates_disable-title font-24 text-bold">{{st("title")}}</div>
                <ActivityIndicatorComponent v-if="!st('data_loaded')"/>
                <div  v-else class="ptu_exassociates_disable-content" v-html="st('content')"/>
            </div>

            <NeedHelpComponent/>

        </div>
      </div>

      <FooterComponent/>
    </div>
  </template>
    
    <script>
    import NavbarBoschComponentNoAuth from '@/components/NavbarBoschComponentNoAuth.vue'
    import FooterComponent from '../components/FooterComponent.vue'
    import NeedHelpComponent from '../components/NeedHelpComponent.vue'
    import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent.vue'
    
    export default {
      props: {
        storeRoute: {
          require: true,
          Type: String
        }
      },
      components: {
    FooterComponent,
    NeedHelpComponent,
    ActivityIndicatorComponent,
    NavbarBoschComponentNoAuth
},
      mounted() {
        let vm = this

        let loop_interval = setInterval(() => {
          if (vm.loaded) {
            vm.ac('load_message')
            clearInterval(loop_interval)
          }
        }, 1000);
      }
    }
    </script>
    <style lang="scss">
      @import "../assets/styles/views/_disableView.scss";
    </style>