import { createApp } from 'vue';
import router from './router'

// Libraries
import BootstrapVueNext from "bootstrap-vue-next"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import PtuExAssociates from './PtuExAssociates.vue'

const app = createApp(PtuExAssociates);

// vuex stores
import Store from '@/store'
import Mixins from '@/mixins'

app.use(router);
app.use(Store);
app.use(BootstrapVueNext);

app.mixin(Mixins);

app.mount('#app');