<template>
  <div v-if="st('show_alert')" class="ptu_exassociates_alert" @click="mt('hiddenOnClick')">

    <div :class="'ptu_exassociates_alert-content' + ' ' + st('class')" >
      <div class="ptu_exassociates_alert-icon">
        <img :src="st('image').url" :alt="st('image').alt">
      </div>
  
      <div class="ptu_exassociates_alert-detail">
        {{ st('message') }}
      </div>
    </div>

  </div>
</template>

<style lang="scss">
  @import "../assets/styles/components/_alertComponent.scss";
</style>
    
<script>

  export default {
    data() {
      return {
        storeRoute: "AlertData"
      }
    },
  }
</script>