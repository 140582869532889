export default {
    namespaced: true,
    state: () => ({
        show_alert: false,
        message: "Bienvenido al proceso de Pago de Utilidades para Ex-Asociados Bosch.",
        image: {
            url: "",
            alt: "",
        },
        class: "alert-default"
    }),
    mutations: {
        mutation_warning(state, message) {
            state.message = message
            state.image = {
                url: "/images/alerts/alert-warning.svg",
                alt: "alert-warning",
            }
            state.class = "alert-warning"
            state.show_alert = true
        },
        mutation_error(state, message) {
            state.message = message
            state.image = {
                url: "/images/alerts/alert-error.svg",
                alt: "alert-error",
            }
            state.class = "alert-error"
            state.show_alert = true
        },
        mutation_done(state, message) {
            state.message = message
            state.image = {
                url: "/images/alerts/alert-success.svg",
                alt: "alert-success",
            }
            state.class = "alert-default"
            state.show_alert = true
        },
        mutation_hiddenOnClick(state) {
            state.show_alert = false
        },
        mutation_hiddenAfterTime(state) {
            setTimeout(function() {
                state.show_alert = false
            }, 6000)
        },
    },
    actions: {
        warning({ commit }, message) {
            commit('mutation_warning', message)
            commit('mutation_hiddenAfterTime')
        },
        error({ commit }, message) {
            commit('mutation_error', message)
            commit('mutation_hiddenAfterTime')
        },
        done({ commit }, message) {
            commit('mutation_done', message)
            commit('mutation_hiddenAfterTime')
        }
    }
}