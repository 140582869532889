<template>
    <button v-if="enable" :class="'button-component required button_' + name" :id="'button_' + name">
        {{ label }}
    </button>
    <button v-else :class="'button-component required button_' + name" :id="'button_' + name" disabled>
        {{ label }}
    </button>
</template>
  
<script>
    export default {
        props: {
            name: {
                Default: "",
                Type: String
            },
            label: {
                Default: "",
                Type: String
            },
            enable: {
                Default: true,
                Type: Boolean
            }
        },
    }
</script>