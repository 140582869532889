// store/MessagesData.js
export default {
    namespaced: true,
    state: () => ({
        messages_list: {
            data: [],
            endpoint: 'catalogs/messages/',
            data_count: 0,
            called: false,
            data_loaded: false,
            mutation: 'MessagesData/mutation_setMessagesList'
        }
    }),
    mutations: {
        mutation_setMessagesList(state, messages) {
            state.messages_list.data = messages;
            state.messages_list.called = true;
            state.messages_list.data_loaded = true;
        },
    },
    actions: {
        async fetchMessagesList({ state, dispatch }) {
            if (!state.messages_list.called) {
                await dispatch('AxiosVuex/__request_gt', {
                    endpoint: state.messages_list,
                    mutation: []
                }, { root: true });
            }
        }
    }
};
