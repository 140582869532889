<template>
    <div v-show="$store.state.app_loaded">
      <NavbarBoschComponent/>
      
      <div class="ptu_exassociates_privacy" id="ptu_exassociates_privacy">
        <div class="row">
            <div class="col-md-12 mb-4">
                <div v-if="st('data_loaded')" class="ptu_exassociates_privacy-title font-24 text-bold mb-3">{{st('title')}}</div>
                <ActivityIndicatorComponent v-if="!st('data_loaded')"/>
                <div v-else  class="ptu_exassociates_privacy-content" v-html="st('content')"/>
            </div>
        </div>
      </div>

      <FooterComponent/>
    </div>
  </template>
    
  <script>
    import NavbarBoschComponent from '../components/NavbarBoschComponent.vue'
    import FooterComponent from '../components/FooterComponent.vue'
    import ActivityIndicatorComponent from '../components/ActivityIndicatorComponent.vue'
    
    export default {
      props: {
        storeRoute: {
          require: true,
          Type: String
        }
      },
      components: {
        NavbarBoschComponent,
        FooterComponent,
        ActivityIndicatorComponent
      },
      mounted() {
        let vm = this

        let loop_interval = setInterval(() => {
          if (vm.loaded) {
            vm.ac('load_message')
            clearInterval(loop_interval)
          }
        }, 1000);
      }
    }
    </script>
    <style lang="scss">
      @import "../assets/styles/views/_privacyView.scss";
    </style>