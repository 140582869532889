<template>
  <div :id="'beneficiary-' + keyNumber" :class="'ptu_exassociates_beneficiary'">
    <template v-for="(input,key) in settings">
      <div v-if="key == 0" :key="'beneficiary-input-' + key + '-' + input.name" :class="input.class_styles">
        <InputComponent 
          class="mb-4"
            :namespace="namespace"
            :settings="{
            label: input.label + ' ' + keyNumber,
            name: input.name + '_' + keyNumber,
            type: input.type,
            required: input.required,
            search: input.search,
            options: input.options,
            value: input.value,
            search_type: input.search_type,
            mutation: input.mutation,
            readonly: input.readonly,
            class_styles: 'input-component required',
            array_key: keyNumber,
          }"
        />
      </div>
    </template>

    <div class="nopadding-right col-lg-12 col-12 row input-component required d-flex">
      <label class="beneficiary_label">{{ getFirstLabel }}</label>
      <template v-for="(input, key) in settings">
        <div v-if="[1].indexOf(key) != -1" :key="'beneficiary-input-' + key + '-' + input.name" :class="input.class_styles">
          <InputComponent  
            :namespace="namespace"
            :settings="{
              label: '',
              name: input.name + '_' + keyNumber,
              type: input.type,
              required: input.required,
              search: input.search,
              options: input.options,
              search_type: input.search_type,
              value: input.value,
              mutation: input.mutation,
              class_styles: 'no_top',
              array_key: keyNumber,
              required_length: input.required_length,
              max_length: input.max_length
            }"
          />
        </div>
      </template>
    </div>

    <div class="nopadding-right col-lg-12 col-12 row input-component required d-flex">
      <!-- <label class="beneficiary_label">{{ getSecondLabel }}</label> -->
      <template v-for="(input,key) in settings">
        <div v-if="[2,3].indexOf(key) != -1" :key="'beneficiary-input-' + key + '-' + input.name" :class="input.class_styles">
          <InputComponent
            :namespace="namespace"
            :settings="{
              label: input.label,
              name: input.name + '_' + keyNumber,
              type: input.type,
              required: input.required,
              search: input.search,
              options: input.options,
              search_type: input.search_type,
              value: input.value,
              mutation: input.mutation,
              class_styles: 'no_top',
              label_class: '',
              input_class: '',
              array_key: keyNumber,
            }"
          />
        </div>
      </template>
    </div>

  </div>
</template>
    
<script>
  import InputComponent from '../components/InputComponent.vue'

  export default {
    props: {
      settings: {
        Default: Object,
        default: function () {
          return {
            label: '',
            type: '',
            required: false,
            search: false,
            options: [],
            value: '',
            mutation: '',
            required_length: '',
            max_length: '',
            search_type: '',
          }
        }
      },
      keyNumber: {
        Type: Number,
        Default: 0
      },
      namespace: {
        Type: String,
        Default: ''
      }
    },

    components: {
      InputComponent,
    },
    methods: {
      addBeneficiary() {
        this.$emit('add_beneficiary');
      }
    },

    computed: {
      getFirstLabel: function () {
        return this.settings[1].label
      },
      getSecondLabel: function () {
        return this.settings[3].label
      }
    }
  }
</script>